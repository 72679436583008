import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"
import CustomLink from "../components/customLink"
import { updateImgixParams } from "../components/_helpers/updateImgixParams"

const CompanyPage = () => {
  let data = useStaticQuery(graphql`
    query CompanyPageQuery {
      prismicCompanyPage {
        data {
          hero_title {
            text
            html
          }
          hero_subtitle {
            html
            text
          }
          team_image {
            fluid(maxWidth: 1140) {
              ...GatsbyPrismicImageFluid
            }
          }
          our_story_headline {
            text
            html
          }
          story_elements {
            image {
              fixed(width: 570, height: 570) {
                ...GatsbyPrismicImageFixed
              }
            }
            text {
              text
              html
            }
          }
          values_headline {
            html
            text
          }
          values {
            value {
              html
              text
            }
            description {
              html
              text
            }
          }
          careers_headline {
            text
            html
          }
          careers_link {
            url
            type
            target
            link_type
            uid
          }
        }
      }
    }
  `)
  if (!data || !data.prismicCompanyPage) return null
  data = data.prismicCompanyPage.data

  if (
    typeof window !== "undefined" &&
    window.__PRISMIC_PREVIEW_DATA__ &&
    window.__PRISMIC_PREVIEW_DATA__.prismicCompanyPage
  ) {
    data = window.__PRISMIC_PREVIEW_DATA__.prismicCompanyPage.data
  }

  return (
    <Layout mainClass="company-page">
      <SEO title="Company" />

      <section className="company-hero hero two">
        <div className="row">
          <div className="inner">
            <div
              className="col text center"
              data-sal="slide-up"
              data-sal-delay="0"
            >
              <h3>{data.hero_title.text}</h3>
            </div>

            <div
              className="col text center"
              data-sal="slide-up"
              data-sal-delay="300"
            >
              <div
                className="subtitle"
                dangerouslySetInnerHTML={{ __html: data.hero_subtitle.html }}
              ></div>
            </div>
          </div>
        </div>

        <div className="row spacer">
          <div className="inner">
            <div className="col"></div>
            <div className="col"></div>
          </div>
        </div>
      </section>

      {data.team_image && (
        <section className="company-team">
          <div className="team-photo">
            <Img
              fluid={data.team_image.fluid}
              imgStyle={{ objectFit: "contain" }}
              alt="VergeSense"
            />
          </div>
        </section>
      )}

      {data.story_elements.length && data.story_elements[0].image.fixed && (
        <section className="company-story">
          <div className="inner">
            <h3>{data.our_story_headline.text}</h3>

            <div className="story-elements">
              {data.story_elements.map((ele, ind) => {
                return <StoryElement data={ele} ind={ind} key={ind} />
              })}
            </div>
          </div>
        </section>
      )}

      {data.values.length && (
        <section className="company-values">
          <div className="inner">
            <div className="row">
              <div className="col text">
                <h4 data-sal="slide-up" data-sal-delay="300">
                  {data.values_headline.text}
                </h4>
              </div>
            </div>
            <div className="row two-col">
              {data.values.map((v, ind) => {
                return (
                  <div
                    className="col text"
                    key={ind}
                    data-sal="slide-down"
                    data-sal-delay={`${150 + ind++ * 50}`}
                  >
                    <h6>
                      <strong>{v.value.text}</strong>
                    </h6>
                    <p>{v.description.text}</p>
                  </div>
                )
              })}
            </div>
          </div>
        </section>
      )}

      {data.careers_link.url && (
        <section className="company-careers">
          <div className="inner">
            <h3 data-sal="slide-up" data-sal-delay="150">
              {data.careers_headline.text}
            </h3>
            <CustomLink
              link={data.careers_link}
              className="button violet"
              data-sal="slide-down"
              data-sal-delay="150"
            >
              View Current Openings
            </CustomLink>
          </div>
        </section>
      )}
    </Layout>
  )
}

export default CompanyPage

// Partner compontent
const StoryElement = ({ data, ind }) => {
  if (!data || !data.image.fixed) return null

  return (
    <div
      className="story-element"
      data-sal="slide-up"
      data-sal-delay={`${150 + ind++ * 50}`}
    >
      <div className="row">
        <div className="col asset">
          <Img
            fixed={updateImgixParams(data.image.fixed, { fit: "crop" })}
            alt={`VergeSense Story ${ind}`}
          />
        </div>

        <div className="col text">
          <div
            className="story-text"
            dangerouslySetInnerHTML={{ __html: data.text.html }}
          ></div>
        </div>
      </div>
      <div className="row spacer" data-sal="slide-down" data-sal-delay="300">
        <span></span>
      </div>
    </div>
  )
}
